//guide page css - create one for each page and include to keep shit organised.

.guide-intro {
	@apply relative w-full h-full flex flex-col items-center justify-center {}

	.blurb {
		@apply w-full px-8 z-20 text-center {}
		.intro-text {
			@apply mx-auto font-headings text-black font-semibold text-2xl space-y-4 {}
			@screen lg {
				@apply max-w-5xl text-4xl px-0 {} } }

 } }		// .btn.cta

.guide-step {
	@apply hidden relative w-full h-full items-center justify-center bg-grey {}

	&.active {
		@apply flex {} }

	&:nth-child(2) {
		@apply bg-white {} }

	.blurb {
		@apply w-full h-full p-8 z-20 text-black text-center flex flex-col justify-start {}
		@screen lg {
			@apply max-w-4xl p-0 {} }

		.step-title {
			@apply font-headings text-3xl uppercase font-bold {}
			margin-top: 7vh;
			margin-bottom: 7vh; }

		.step-content {
			@apply space-y-4 {}
			img {
				@apply mb-12 {} } } } }
