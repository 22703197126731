//nav
.navigation-controls {
	@apply uppercase text-white font-semibold {}

	.hamburger {
		@apply text-3xl {}
		&:hover {
			@apply cursor-pointer {} } } }

.main-nav-ui {
	@apply flex flex-col items-start justify-start fixed w-full h-full right-0 bottom-0 top-0 bg-value-blue transform translate-x-full transition transition-transform {}
	&.open {
		@apply translate-x-0 duration-200 {} }

	@screen lg {
		@apply w-auto {}
		min-width: 30%; }
	@screen xl {
		@apply pr-12 {} }

	nav.main {
		@apply flex flex-col flex-grow {}
		.nav-item {
			// each top level nav item.
			@apply py-8 pl-12 uppercase text-white font-semibold text-2xl {}

			@screen lg {
				@apply pl-20 {} }

			&.parent {
				.nav-title {
					@apply uppercase {} }

				.children {
					@apply absolute min-w-full h-auto right-0 left-0 bg-black text-white invisible pointer-events-none {}
					top: 100%; }

				// sub-nav lists:
				.list-title {
					@apply text-lg border-b border-white py-6 {} }

				.link-list {
					@apply flex flex-col py-4 {}
					a {
						@apply py-4 {}
						&:hover {
							@apply underline cursor-pointer {} } } } }

			&.active,
			&:hover {
				@apply cursor-auto {}
				&:not(.parent) {
					.nav-title {
						@apply underline cursor-pointer {} } }
				.children {
					@apply visible pointer-events-auto {} } } } }

	.connect-icons {
		@apply py-8 px-12 text-white text-3xl {}
		@screen xl {
			@apply pl-20 pb-24 {} }
		a {
			@apply mr-4 {} } } }

.navigation-controls,
nav.main {
	a {
		@apply cursor-pointer tracking-normal {}

		&.active > a {
			@apply underline {} }

		&.active-child > a {
			@apply underline {} }

		&.login-btn {
 } } }			// stuff
