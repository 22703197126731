.transition-fade {
	transition: 0.5s;
	opacity: 1; }

html.is-animating .transition-fade {
	opacity: 0; }

a:hover {
	@apply underline {} }

[x-cloak] {
	display: none !important; }

.min-w-lg {
	min-width: theme('screens.lg'); }

.btn {
	@apply rounded-full px-12 py-4 bg-black text-white inline-block text-lg text-center font-bold {}

	&.white {
		@apply bg-white text-black {} }

	&.grey {
		@apply bg-grey text-black {} }

	&.blue {
		@apply bg-value-blue text-white {} }

	&.hollow {
		@apply bg-transparent border border-solid border-darkgrey text-darkgrey {}

		&.white {
			@apply border-darkgrey text-darkgrey {}
			&:hover {
				@apply bg-grey {} } }
		&.grey {
			@apply border-darkgrey text-darkgrey {}
			&:hover {
				@apply bg-grey {} } }
		&.blue {
			@apply border-value-blue text-value-blue {}
			&:hover {
				@apply bg-value-blue text-white {} } } }

	&.slim {
		@apply px-8 py-2 {} }

	&:hover {
		@apply no-underline cursor-pointer bg-blend-darken {} } }

// A sane set of default text margin/padding settings for contet/bard blocks across the site:
.bard-block-heading {
	@apply mb-6 {}
	@screen lg {
		@apply mb-8 {} } }
.bard-block {
	@apply space-y-4 {}
	// h1,h2
	// 	@apply mb-5
	// h3,h4,h5
	// 	@apply mb-4
	h3 {
		@apply normal-case font-normal {} }
	p {
		@apply text-base leading-relaxed {} }

	ul {
		@apply list-disc pl-4 {}
		li {
			@apply mb-2 {} } }

	hr {
		@apply my-16 {} } }

// form elements
select {
	@apply px-4 py-2 {} }

// page headers/banners
.page-banner {
	@apply grid grid-cols-12 {} }

.page-content {
	@apply pb-0 px-4 {}
	@screen lg {
		@apply pb-32 {} } }

// page container upper-limit overrides (for locking content to a smaller container width on larger screens)
.contain-max-md {
	@apply px-0 {}
	max-width: theme('screens.md');
	@screen md {
		@apply px-0 {} } }
.contain-max-lg {
	@apply px-0 {}
	max-width: theme('screens.lg');
	@screen lg {
		@apply px-0 {} } }
.contain-max-xl {
	@apply px-0 {}
	max-width: theme('screens.xl');
	@screen xl {
		@apply px-0 {} } }
.contain-max-2xl {
	@apply px-0 {}
	max-width: theme('screens.2xl');
	@screen 2xl {
		@apply px-0 {} } }
.contain-max-3xl {
	@apply px-0 {}
	max-width: theme('screens.3xl');
	@screen 3xl {
		@apply px-0 {} } }


// contact CTA widget
.contact-cta {
	.contact-cta-head {


		background-image: url('../assets/cvf-wave.svg');
		background-position: center top;
		background-repeat: no-repeat; }
	.contact-cta-content {
 } }		// stuff

// general description block (used on welcome page and also CTA on other pages)
.cvf-desc {
	@apply relative w-full bg-no-repeat bg-cover bg-center flex items-center justify-center {}
	@screen lg {
		@apply py-32 {} }
	z-index: 1;
	&::after {
		@apply absolute w-full h-full bg-black opacity-50 {}
		z-index: 2;
		content: ""; }

	.blurb {
		@apply w-full p-8 z-20 font-headings text-white font-semibold text-2xl text-center {}
		@screen lg {
			@apply max-w-4xl text-4xl p-0 {} }

 } }		// .btn.cta
