//base html stuff
html,
body {
	@apply bg-white text-black font-body h-full min-h-full antialiased flex flex-col {}
	-webkit-overflow-scrolling: touch;
	text-rendering: optimizeLegibility;

	font-size: 90%;
	@screen sm {
		font-size: 100%; } }

* {
	box-sizing: border-box;
	&:focus {
		outline: none; } }

img {
	display: block;
	width: 100%; }

.barba-wrapper {
	@apply relative flex flex-1 flex-col {}
	> article {
		@apply flex flex-1 flex-col w-full {} } }


// Header
.header {
	@apply sticky top-0 w-full p-4 bg-pure-black z-50 {}

	.wrap {
		@apply flex items-center justify-between h-full {}

		.logo {
			@apply py-1 flex justify-start items-center {}
			max-width: 100px;
			@screen md {
				max-width: none; }

			.logotype {
				@apply text-white uppercase ml-4 font-extrabold hidden md:block {} } }

		@screen xl {
			@apply px-4 {} } } }

// Page Content
.page-content {
	.page-intro {
		@apply my-12 {} } }

// Footer
footer {
	@apply relative w-full p-4 flex space-y-2 lg:space-y-0 flex-col lg:flex-row justify-between items-center bg-pure-black text-white self-end {}
	.copy {
		@apply text-left uppercase {} }
	.title {
		@apply text-center uppercase font-bold {} }
	.socials {
		@apply flex items-center justify-end {}
		i {
			@apply text-xl mx-4 {} } }

	&.leaving {
		@apply opacity-0 {} } }


// Login modal
.login-modal {
	@apply fixed top-0 z-30 w-full h-full flex items-center justify-center bg-pure-black bg-opacity-75 opacity-0 pointer-events-none transition duration-300 {}
	&.active {
		@apply opacity-100 pointer-events-auto {}

		.login-box {
			@apply translate-y-0 {} } }

	.login-hitarea {
		@apply absolute z-20 w-full h-full {} }

	.login-box {
		@apply relative z-30 bg-white text-black rounded-3xl max-w-md flex flex-col p-12 transition ease-out duration-500 transform translate-y-12 {}

		.user-type {
			@apply flex flex-col items-stretch {}
			.btn {
				@apply rounded-md my-2 {} } }

		.login-form {
			@apply absolute opacity-0 pointer-events-none {}
			&.active {
				@apply relative opacity-100 pointer-events-auto {}
				form {
					@apply flex {} } }

			form {
				@apply w-full my-4 flex flex-col items-center hidden {}

				.field {
					@apply w-full flex items-center border-b my-4 {}
					input {
						@apply order-2 flex-grow bg-transparent py-2 {} }
					label {
						@apply order-1 mr-4 uppercase transition  ease-out duration-300 {} } }

				input.submit {
					@apply mt-8 bg-transparent border {} } } }

		// colour definitions for each type of form
		&.as-user {
			@apply bg-pure-black text-darkgrey {}
			form {
				.field {
					border-grey {}
					input {
						@apply text-darkgrey {}
						&:focus {
							@apply text-grey {} } }
					label {
						@apply text-darkgrey {} }
					input:focus + label {
						@apply text-grey {} } }
				input.submit {
					@apply text-grey border-grey {} } }

			.btn.user {
				@apply bg-value-blue text-white border-value-blue {} } }

		&.as-practitioner {
			@apply bg-white text-black {}
			form {
				.field {
					border-pure-black {}
					input {
						@apply text-pure-black {} }
					label {
						@apply text-black {} }
					input:focus + label {
						@apply text-pure-black {} } }
				input.submit {
					@apply text-black border-black {} } }

			.btn.prac {
				@apply bg-value-orange text-white border-value-orange {} } } } }


// Hidden Print sections
.print-only {
	@media screen {
		@apply hidden {} } }

// AOS definitions
*[data-aos] {
	@apply transition duration-500 ease-in-out-quad {} }
*[data-aos="fadeup"] {
	@apply transform translate-y-1 opacity-0 {}
	&.animate {
		@apply opacity-100 translate-y-0 {} } }
