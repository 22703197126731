//dashboard pages css - create one for each page and include to keep shit organised.

#clientdashboard {
	.dashboard {
		@apply grid grid-cols-12 gap-12 {}

		.flash-message {
			@apply p-4 bg-value-orange text-center {} }

		.block-title {
			@apply mb-4 pb-2 border-b border-darkgrey {} }

		.profile {
			@apply col-span-3 {}
			.name {
				@apply mb-4 font-headings text-darkgrey uppercase {} }
			.profile-image {
				@apply w-64 h-auto border border-beige border-4 bg-white mb-12 {} } }

		.submissions {
			@apply col-span-full {}

			// Submission Row:
			.submission {
				@apply col-span-full border-b border-grey py-4 {}
				.details {
					@apply flex justify-between items-center {} }
				.revisions {
					.revision {
						@apply flex justify-between items-center pl-4 pt-4 {} } } } } } }

#staffdashboard {
	.dashboard {
		@apply grid grid-cols-12 gap-12 overflow-x-auto {}

		.flash-message {
			@apply p-4 bg-value-orange text-center {} }

		.block-title {
			@apply mb-4 pb-2 border-b border-darkgrey {} }

		.profile {
			@apply col-span-3 {}
			.name {
				@apply mb-4 font-headings text-darkgrey uppercase {} }
			.profile-image {
				@apply w-64 h-auto border border-beige border-4 bg-white mb-12 {} } }

		.reports {
			@apply col-span-12 {}

			// Report Row:
			.report {
				@apply col-span-full border-b border-grey py-4 {}
				.details {
					@apply flex justify-between items-center {} }
				.revisions {
					.revision {
						@apply flex justify-between items-center pl-4 pt-4 {} } } } } } }

#revise {
	.submission-revision {
		@apply py-8 my-8 border-t border-b border-darkgrey {}
		.row {
			@apply grid grid-cols-12 gap-4 py-4 items-center {}
			.value {
				@apply col-span-8 {}
				.value-label {
					@apply block font-bold {} }
				.value-statement {
					@apply block {} } }
			.answer {
				@apply col-span-4 {}

				select {
					@apply rounded-full py-2 px-4 border border-black font-bold {}

					&[data-rank="2"] {
						@apply bg-value-purple text-white {} }
					&[data-rank="3"] {
						@apply bg-value-blue text-white {} }
					&[data-rank="4"] {
						@apply bg-value-orange text-white {} }
					&[data-rank="5"] {
						@apply bg-value-green text-white {} } } } } } }
