//all print-only styles can go here.

@media print {
	* {
		-webkit-print-color-adjust: exact;
		color-adjust: exact; }

	.print-only {
		@apply block {} }

	.barba-wrapper {
		@apply pt-0 {}

		> article {
			@apply block {} } }

	// hide header/toolkit/footer on results page, leaving a nice standalone graph.
	.header {
		@apply hidden {} }
	footer {
		@apply hidden {} }

	.results-container {
		@apply h-auto w-full min-w-0 {}
		break-after: always;
		.worldviews-bar {
			@apply mt-0 {} }
		.toolkit {
			@apply hidden {} }
		.inactives {
			@apply hidden {} }
		.results {
			.buffer {
				@apply hidden {} }
			.value-clusters-bar {
				.definitions-label {
					@apply hidden {} } }
			.value p {
				@apply font-bold {} } } }

	.print-warning {
		page-break-after: always;
		@apply w-full h-screen flex justify-center items-center text-center {}

		p {
			@apply text-4xl {} } }


	.printable-results-list {
		page-break-before: always;

		.cluster {
			page-break-inside: avoid;
			@apply my-8 pb-8 border-b border-darkgrey {}
			&:last-child {
				@apply pb-0 mb-0 border-b-0 {} }

			.cluster-title {
				@apply my-0 mb-4 {} }
			.cluster-desc {
				@apply mb-8 {} }

			.cluster-values,
			.cluster-capabilities {
				@apply mb-4 {}
				.value {
					@apply my-2 leading-8 {} // ml-4

					p > strong {
						@apply rounded-lg py-1 px-4 text-white {} }

					// Capability
					&[data-rank="2"] {
						p > strong {
							@apply bg-value-purple {} } }
					// Latent
					&[data-rank="3"] {
						p > strong {
							@apply bg-value-blue {} } }
					// Mid
					&[data-rank="4"] {
						p > strong {
							@apply bg-value-orange {} } }
					// High
					&[data-rank="5"] {
						p > strong {
							@apply bg-value-green {} } } } } } } }

