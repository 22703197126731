//about page css - create one for each page and include to keep shit organised.

.feature-quote {
	@apply bg-navy text-center py-20 text-3xl text-white {} }

.feature-text {
	p {
		@apply text-white leading-relaxed text-lg mb-4 {} } }


.creators {
	@apply relative grid grid-cols-12 gap-10 pb-32 {}
	@screen lg {
		@apply pb-60 {} }
	.creator {
		@apply col-span-4 col-start-3 pt-16 {}
		&:nth-of-type(2) {
			@apply col-start-7 {} }

		.profile-image {
			@apply w-64 h-auto border border-beige border-4 bg-white mb-12 {} }

		.name {
			@apply mb-4 {} }
		.role {
			@apply text-darkgrey mb-8 {} }
		.bio {
 } } }			// stuff

.about-footer {
	@apply pt-40 {}
	@screen lg {
		@apply pt-80 {} }

	background-image: url('../assets/cvf-wave.svg');
	background-position: center top;
	background-repeat: no-repeat; }
