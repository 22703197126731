// Dynamic Page components UX
.page-components {
	@apply grid grid-cols-1 {}
	@screen lg {
		@apply grid-cols-12 {} }

	.component {
		@apply p-4 {}

		&.component-w-1 {
			@apply col-span-full {}
			@screen lg {
				@apply col-span-1 {} } }
		&.component-w-2 {
			@apply col-span-full {}
			@screen lg {
				@apply col-span-2 {} } }
		&.component-w-3 {
			@apply col-span-full {}
			@screen lg {
				@apply col-span-3 {} } }
		&.component-w-4 {
			@apply col-span-full {}
			@screen lg {
				@apply col-span-4 {} } }
		&.component-w-5 {
			@apply col-span-full {}
			@screen lg {
				@apply col-span-5 {} } }
		&.component-w-6 {
			@apply col-span-full {}
			@screen lg {
				@apply col-span-6 {} } }
		&.component-w-7 {
			@apply col-span-full {}
			@screen lg {
				@apply col-span-7 {} } }
		&.component-w-8 {
			@apply col-span-full {}
			@screen lg {
				@apply col-span-8 {} } }
		&.component-w-9 {
			@apply col-span-full {}
			@screen lg {
				@apply col-span-9 {} } }
		&.component-w-10 {
			@apply col-span-full {}
			@screen lg {
				@apply col-span-10 {} } }
		&.component-w-11 {
			@apply col-span-full {}
			@screen lg {
				@apply col-span-11 {} } }
		&.component-w-12 {
			@apply col-span-full {}
			@screen lg {
				@apply col-span-12 {} } }

		&.component-o-0 {
			@apply col-start-auto {} }
		&.component-o-1 {
			@apply col-start-auto {}
			@screen lg {
				@apply col-start-2 {} } }
		&.component-o-2 {
			@apply col-start-auto {}
			@screen lg {
				@apply col-start-3 {} } }
		&.component-o-3 {
			@apply col-start-auto {}
			@screen lg {
				@apply col-start-4 {} } }
		&.component-o-4 {
			@apply col-start-auto {}
			@screen lg {
				@apply col-start-5 {} } }
		&.component-o-5 {
			@apply col-start-auto {}
			@screen lg {
				@apply col-start-6 {} } }
		&.component-o-6 {
			@apply col-start-auto {}
			@screen lg {
				@apply col-start-7 {} } }
		&.component-o-7 {
			@apply col-start-auto {}
			@screen lg {
				@apply col-start-8 {} } }
		&.component-o-8 {
			@apply col-start-auto {}
			@screen lg {
				@apply col-start-9 {} } }
		&.component-o-9 {
			@apply col-start-auto {}
			@screen lg {
				@apply col-start-10 {} } }
		&.component-o-10 {
			@apply col-start-auto {}
			@screen lg {
				@apply col-start-11 {} } }
		&.component-o-11 {
			@apply col-start-auto {}
			@screen lg {
				@apply col-start-12 {} } }

		&.vmargin-none {
			@apply my-4 {} // needs a baseline margin on mobile as everything is stacked
			@screen lg {
				@apply my-0 {} } }

		&.vmargin-x-small {
			@apply my-4 {}
			@screen lg {
				@apply my-8 {} } }

		&.vmargin-small {
			@apply my-8 {}
			@screen lg {
				@apply my-12 {} } }

		&.vmargin-medium {
			@apply my-6 {}
			@screen lg {
				@apply my-16 {} } }

		&.vmargin-large {
			@apply my-12 {}
			@screen lg {
				@apply my-20 {} } }

		&.vmargin-x-large {
			@apply my-16 {}
			@screen lg {
				@apply my-28 {} } }

		&.std {
			.bard-block-heading {
 } }				// stuff

		&.feature-text {
			@apply text-2xl {}
			@screen lg {
				@apply text-4xl leading-tight {} } }

		&.divider {
			&.with-line {
				@apply border-b border-solid {} } }

		&.image-block {
			&.component-w-12 {
				@apply p-0 {} }
			&.vmargin-none {
				@apply my-0 {} }
			img {
				@apply mb-8 {}
				@screen lg {
					@apply mb-12 {} }

				&:last-child {
					@apply mb-0 {} } }

			&.with-content {
				@apply grid lg:grid-cols-12 bg-center bg-cover items-center {}
				.content.light {
					@apply text-white {} }
				.content.dark {
					@apply text-black {} } } }

		&.video-embed-block {
			.video-wrapper {
				@apply relative h-0 {}
				&.a16-9 {
					padding-bottom: (9 / 16) * 100%; }
				&.a4-3 {
					padding-bottom: (3 / 4) * 100%; }
				&.a1-1 {
					padding-bottom: 100%; }
				&.a3-4 {
					padding-bottom: (4 / 3) * 100%; }
				&.a9-16 {
					padding-bottom: (16 / 9) * 100%; }
				iframe {
					@apply absolute top-0 left-0 w-full h-full {} } } }

		&.downloads-block {
			@apply flex justify-between {}
			.title {
				@apply uppercase flex-shrink {} } } } }
