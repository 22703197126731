//results page css - create one for each page and include to keep shit organised.

.results-container {
	// @apply relative w-full h-full overflow-x-scroll
	@apply relative w-full h-full {}
	font-family: sans-serif;

	@screen lg {
		overflow-x-auto {} }

	// "toolkit" value filter UI
	.toolkit {
		@apply fixed top-20 right-20 {}
		width: 200px;
		z-index: 21;
		.header {
			@apply relative h-auto bg-value-blue py-2 uppercase text-white text-center font-bold text-sm {}
			&:hover {
				@apply cursor-pointer underline {} } }
		.menu {
			@apply bg-white text-black shadow-xl -translate-y-full transition-transform duration-500 {}
			&.open {
				@apply translate-y-0 {} }

			.subheader {
				@apply relative h-auto bg-darkgrey py-2 uppercase text-white text-center font-bold text-sm {} }

			.opts {
				@apply px-4 py-2 uppercase {}
				.opt {
					@apply flex justify-between text-xs py-3 border-t border-grey {}

					&:first-child {
						@apply border-t-0 {} }

					.action {
						@apply text-value-blue {}
						&:hover {
							@apply cursor-pointer underline {} } }

					.switch {
						@apply relative inline-block {}
						width: 40px;
						height: 19px;
						input {
							@apply w-0 h-0 opacity-0 {}
							&:checked + .slider:before {
								transform: translateX(21px); } }

						.slider {
							@apply absolute cursor-pointer inset-0 bg-grey duration-300 {}
							&::before {
								@apply absolute bg-darkgrey duration-300 {}
								content: "";
								width: 17px;
								height: 17px;
								left: 1px;
								bottom: 1px; }

							&.round {
								border-radius: 34px;
								&::before {
									border-radius: 50%; } } } }

					&.capabilities {
						.switch input:checked + .slider {
							@apply bg-value-blue bg-opacity-20 {}
							&::before {
								@apply bg-value-blue {} } } }
					&.high {
						.switch input:checked + .slider {
							@apply bg-value-green bg-opacity-20 {}
							&::before {
								@apply bg-value-green {} } } }
					&.mid {
						.switch input:checked + .slider {
							@apply bg-value-orange bg-opacity-20 {}
							&::before {
								@apply bg-value-orange {} } } }
					&.latent {
						.switch input:checked + .slider {
							@apply bg-value-purple bg-opacity-20 {}
							&::before {
								@apply bg-value-purple {} } } }
					&.worldviews {
						.switch input:checked + .slider {
							@apply bg-grey bg-opacity-80 {}
							&::before {
								@apply bg-black {} } } }
					&:last-child {
						@apply border-b-0 {} } } } } }


	// "world views" UI
	.worldviews-bar {
		@apply relative mt-20 w-full bg-grey {}
		.worldviews {
			@apply relative flex-grow {}

			.wv-row {
				@apply w-full grid gap-px grid-cols-8 {}

				.opt {
					@apply col-span-2 flex flex-col justify-between text-xs text-center py-3 transition-transform duration-300 border-t border-b border-grey {}

					.wv-title {
						@apply font-bold uppercase {}
						&:hover {
							@apply cursor-pointer {} } }
					.wv-desc {
						@apply text-xs hidden pt-3 {} }

					&:hover {
						@apply cursor-pointer underline {} }

					&.active {
						@apply bg-darkgrey text-white border-white {}
						.wv-desc {
 } } } } } }							// @apply block
	// "toolkit" positioning
	.toolkit {
		min-width: 200px; }

	// Results UI
	.results {
		@apply relative h-full w-full {}
		min-width: 1300px;

		// dimmer overlay for stage descriptions
		.dim {
			@apply absolute w-full h-full inset-0 opacity-0 z-20 pointer-events-none transition-all duration-300 {}
			&.dark {
				@apply bg-black {} }
			&.light {
				@apply bg-white {} }
			&.active {
				@apply opacity-95 {} } }

		// rows positioning
		.values-row {
			@apply relative flex items-stretch {}
			.buffer {
				@apply w-44 {} }
			.grid {
				@apply w-8/12 {} }

			p {
				@apply mb-0 text-xs {} } }

		// column defaults:
		.values-column {
			@apply col-span-1 flex flex-col transition-all duration-700 {} }

		// global 'value' style definitions
		.value {
			@apply relative text-center text-xs text-white bg-grey flex items-center justify-center h-8 overflow-clip {}

			&[data-rank="2"] {
				@apply bg-value-purple {} }
			&[data-rank="3"] {
				@apply bg-value-blue py-4 {} }
			&[data-rank="4"] {
				@apply bg-value-orange py-5 {} }
			&[data-rank="5"] {
				@apply bg-value-green py-6 {} }

			p {
				@apply w-full px-2 overflow-hidden overflow-ellipsis text-xs leading-none mb-0 {} }

			&:hover {
				@apply cursor-pointer bg-opacity-60 {} }

			.tooltip {
				@apply absolute z-10 left-full top-px bg-black text-white text-xs text-left shadow-lg p-3 opacity-0 pointer-events-none -translate-x-6 transition-all duration-200 {}
				min-width: 160%;
				@screen lg {
					min-width: 120%; }

				.t-title {
					@apply uppercase font-bold text-value-orange pr-3 pointer-events-none {} }
				.t-close {
					@apply absolute top-3 right-3 text-value-orange cursor-pointer {} }
				.t-statement {
					@apply mt-1 {} }

				&::after {
					@apply absolute left-0 top-0 w-0 h-0 border-l-0 {}
					content: '';
					border: 10px solid transparent;
					border-right-color: #262226;
					border-left: 0;
					margin-left: -10px;
					margin-top: 10px; } }

			&.active {
				.tooltip {
					@apply opacity-100 pointer-events-auto translate-x-px {} } } }

		// "Top" grid of user results/values
		.grid.values {
			@apply gap-px relative flex-grow {}

			// overlay of stage descriptions
			.stage-descriptions {
				@apply absolute w-full h-full inset-0 z-30 grid {}
				.desc-col {
					@apply relative z-30 col-span-3 flex flex-col justify-end {}
					.description {
						@apply border-l border-white text-white text-xs px-4 pt-0 pb-12 {}
						.top {
							@apply flex justify-between items-center {}
							.t {
								@apply font-bold m-0 {} }
							i:hover {
								@apply cursor-pointer {} } } }

					&.flipped {
						.description {
							@apply border-l-0 border-r {} } } } }

			// overlay of worldview descriptions
			.worldview-descriptions {
				@apply absolute w-full h-full inset-0 z-30 -top-20 opacity-0 pointer-events-none transition-opacity duration-300 {}
				&.active {
					@apply opacity-100 pointer-events-auto {} }

				.wv-descriptor-row {
					@apply relative w-full grid grid-cols-8 {}

					.wv-descriptor {
						@apply h-0 opacity-0 transition-all duration-300 col-span-2 {}
						&.show {
							@apply h-36 mb-8 opacity-100 {} } } } }

			.value {
				@apply mb-px {}
				&:first-of-type {
					@apply rounded-t-md {} } } }

		// "Bar" of Life stage definitions
		.value-clusters-bar {
			@apply bg-black text-white sticky bottom-0 {}
			.definitions-label {
				@apply flex items-center justify-start uppercase font-extrabold pl-4 {}
				@screen lg {
					@apply pl-0 {} } }
			.grid.definitions {
				@apply flex-grow bg-black text-white uppercase text-xs font-semibold text-center items-stretch {}

				.definition-button {
					@apply flex items-center justify-center text-center border-l border-r border-black {}
					&:hover {
						@apply underline cursor-pointer {} }
					&.active {
						border-left-color: white; }

					&.flipped {
						&.active {
							@apply border-black {}
							border-right-color: white; } } } } }

		// "Lower" grid of capabilities
		.grid.capabilities {
			@apply gap-px flex-grow {}

			.value {
				@apply mt-px bg-value-blue text-white {}

				&:last-of-type {
					@apply rounded-b-md {} } } }

		// "Bottom" Block for inactive values
		.inactive-values {
			@apply w-9/12 {}
			.blurb {
				@apply text-center pb-8 text-sm {}
				.blurb-title {
					@apply font-bold uppercase text-sm {} } }

			.inactive-selector {
				@apply grid grid-cols-12 gap-3 {}

				//incactive titles are shown on PDF instead of selector
				.inactive-titles {
					.ititle {
						@apply pt-2 mx-4 pb-4 text-sm font-bold text-right {} } }

				.inactive-list {
					.igroup {
						@apply flex flex-wrap mt-2 text-sm {} }
					.ivalue {
						@apply text-black mx-4 mb-4 {} } } }

			.inactive-selector {
				.selector-ui {
					@apply pr-4 bg-white border border-black rounded-sm {}
					select {
						@apply w-full px-4 py-2 bg-transparent {} } } } } } }
